.mainlift {
    position: relative;
    width: 100%;
    height: 400px;
    background-image: url('./Images/LI.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainlift::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    z-index: 1;
}

.textlift {
    position: relative;
    z-index: 2;
    padding: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.h3lift {
    font-weight: bold;
    font-size: 5rem;
    margin: 0;
    color: rgb(65, 117, 177);
}

.solift {
    font-size: 1.2rem;
    margin: 10px 0 0;
    color: lightgray;
}

.ml {
    position: relative;
    width: 100%; 
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.ml1 {
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: rgba(72, 35, 35, 0.199);
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    z-index: 0;
    width: 100%;
    overflow: hidden;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.overlaybox {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden; /* This will hide any overflow */
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%; /* Optional: define a height */
    max-height: 300px; /* You can adjust this to your desired height */
}

.box {
    max-width: 35%;
    padding: 20px;
    margin: 0;
    margin-left: 12rem;
    text-align: left;
    flex: 1; /* Ensure boxes take equal space */
}

.box h4 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.box ul {
    list-style-type: none;
    padding: 0;
    font-size: 1rem;
}

.boximage {
    width: 100%;
    height: 100%; /* This will maintain the aspect ratio */
    object-fit: contain; /* Ensures the entire image is visible */
    border-radius: 10px;
}

.imagerow {
    display: flex;
    justify-content: center; /* Center the images in the row */
    margin: 20px 0; /* Space between rows */
}

.ttimage {
    width: 200px; /* Set a fixed width for all images */
    height: 200px; /* Set a fixed height for all images */
    margin: 0 10px; /* Space between images */
    object-fit: cover; /* Maintain aspect ratio and cover the entire area */
    border-radius: 10px; /* Optional: add border radius */
    border: 2px solid #000; /* Add border (change color as needed) */
}

/* General mobile responsiveness */
@media (max-width: 768px) {
    .mainlift {
      height: 300px;
      background-size: contain;
    }
  
    .h3lift {
      font-size: 3rem;
    }
  
    .solift {
      font-size: 1rem;
    }
  
    .ml1 {
      font-size: 3rem;
      top: 5%;
      width: 80%; /* Adjust width for mobile */
    }
  
    .overlaybox {
      flex-direction: column; /* Stack items vertically on mobile */
      margin: 1rem;
      height: auto; /* Remove fixed height */
      max-height: none; /* Remove max height constraint */
    }
  
    .box {
      margin: 0;
      padding: 10px;
      max-width: 100%; /* Take full width on mobile */
      text-align: center; /* Center the text for better readability */
      margin-left: 0;
    }
  
    .boximage {
      width: 100%; /* Make image take full width */
      height: auto; /* Let the image adjust its height */
    }
  
    .imagerow {
      flex-direction: column; /* Stack images vertically on mobile */
      align-items: center;
    }
  
    .ttimage {
      width: 100%; /* Full width on mobile */
      margin: 10px 0; /* Space between images */
    }
  }
  
  /* Tablet responsiveness */
  @media (min-width: 769px) and (max-width: 1024px) {
    .mainlift {
      height: 350px;
      background-size: contain;
    }
  
    .h3lift {
      font-size: 4rem;
    }
  
    .solift {
      font-size: 1.1rem;
    }
  
    .ml1 {
      font-size: 4rem;
    }
  
    .overlaybox {
      margin: 1.5rem;
      height: auto; /* Remove fixed height */
      max-height: none; /* Remove max height constraint */
    }
  
    .box {
      margin: 0;
      padding: 15px;
      max-width: 100%; /* Take full width on tablet */
      text-align: left;
    }
  
    .boximage {
      width: 100%; /* Make image take full width */
      height: auto; /* Let the image adjust its height */
    }
  
    .imagerow {
      flex-direction: row;
      justify-content: space-around; /* Spread out the images */
    }
  
    .ttimage {
      width: 150px;
      height: 150px;
      margin: 10px;
    }
  }
  