/* Main container styling */
.main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f9fafb;
}

/* Image container styling */
.image_container {
  position: relative;
  height: 400px;
  width: 100%;
  animation-name: slide-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.image_container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.text_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.text_overlay h2 {
  color: #ffffff;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.text_overlay h6 {
  color: #ffffff;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.fo {
  margin-top: -2rem;
}

/* Form container styling */
.container {
  width: 100%;
  max-width: 44rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  padding: 4rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form heading styling */
.heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  text-align: center;
}

/* Form element styling */
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input,
.textarea,
.file_input {
  width: 100%;
  max-width: 20rem;
  background-color: #f3f4f6;
  color: #1f2937;
  border: none;
  border-radius: 0.375rem;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all 0.15s ease-in-out;
}

.input:focus,
.textarea:focus,
.file_input:focus {
  background-color: #e5e7eb;
  outline: none;
  border: 1px solid #3b82f6;
}

/* Button styling */
.button {
  background: linear-gradient(to right, #6366f1, #3b82f6);
  color: white;
  font-weight: bold;
  padding: 1rem 2.5rem;
  border-radius: 0.375rem;
  margin-top: 1rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(to right, #4f46e5, #2563eb);
}

/* Animation */
@keyframes slide-in {
  from {
      transform: translateY(-50px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

/* Responsive Styles */

/* Tablet (768px and below) */
@media (max-width: 768px) {
  .main_container {
      padding: 1.5rem;
  }

  .image_container {
      height: 300px;
  }

  .text_overlay h2 {
      font-size: 2rem;
  }

  .text_overlay h6 {
      font-size: 1.1rem;
  }

  .container {
      padding: 3rem;
      max-width: 90%; /* Adjusts container to fit screen width on smaller devices */
  }

  .button {
      padding: 0.8rem 2rem;
  }
}

/* Mobile (480px and below) */
@media (max-width: 480px) {
  .main_container {
      padding: 1rem;
  }

  .image_container {
      height: 200px;
  }

  .text_overlay h2 {
      font-size: 1.5rem;
  }

  .text_overlay h6 {
      font-size: 1rem;
  }

  .container {
      padding: 2rem;
      max-width: 80%; /* Make form container fit to screen width */
  }

  .heading {
      font-size: 1.3rem;
  }

  .input,
  .textarea,
  .file_input {
      padding: 0.8rem;
      max-width: 100%;
  }

  .button {
      padding: 0.7rem 1.8rem;
      font-size: 0.9rem;
  }
}
