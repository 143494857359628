@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


.sectionpadding {
  padding: 2rem;
}

.sbfooterlinksdiv img {
  height: auto;
  width: 200px;
  padding-top: 1.68rem; 
}

.iconspacing {
  margin-right: 1rem;
}



.footer {
background: linear-gradient(90deg, rgba(255, 255, 255, 0.639),  #509ddf);
  padding: 1rem 0.25rem; 
  bottom: 0;
    left: 0;
    right: 0;
  color: black;
  position: relative;
  overflow: hidden;
}



.footer::before {
  content: '';
  position: absolute;
  top: -100px; 
  right: -100px; 
  width: 400px; 
  height: 400px; 
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 0;
}

.sbfooter {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

.sbfooterlinks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem; 
  padding: 0 0.5rem; 
}


.sbfooterlinksdiv {
    width: 200px;
    margin: 0.5rem;
    color: black;
    transition: transform 0.3s ease; 
  }
  
  .sbfooterlinksdiv:hover {
    transform: scale(1.05); 
  }
  
  .sbfooterlinksdiv h4 {
    font-size: 18px; 
    margin-bottom: 0.5rem; 
    font-weight: 700;
    position: relative;
    padding: 0.5rem; 
    border-radius: 5px; 
    background-color: rgba(24, 62, 168, 0.404);
    color: white; 
    box-shadow: 0 2px 5px black; 
  }
  
  .sbfooterlinksdiv h4::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: #4574ad;
    transition: width 0.3s ease; 
  }
  
  .sbfooterlinksdiv h4:hover::after {
    width: 100%; 
  }
  
  .sbfooterlinksdiv p {
    font-size: 15px; 
    line-height: 1.6; 
    color: black;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .sbfooterlinksdiv p:hover {
    color: #4574ad; 
  }
  
  .sbfooterlinksdiv p i {
    margin-right: 8px;
    font-size: 18px; 
    color: black; 
  }
  

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: black;
}

.sbfooterbelow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0; 
  margin: 0; 
  border-top: 1px solid #414141;
}

.sbfooterbelowlinks {
  display: flex; 
  align-items: center;
}

.sbfooterbelowlinks p {
  font-size: 14px;
  color: #070303;
  margin-left: 1rem; 
  position: relative; 
  transition: color 0.3s ease;
}

.sbfooterbelowlinks p:hover {
  color:#000000; 
}

.sbfooterbelowlinks p:hover::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ad4545; 
  transition: all 0.3s ease;
}

.hr {
  border: none;
  height: 1px;
  background-color: red;
  margin: 1rem 0;
}

.sbfootercopyright {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  padding: 0;
  margin: 0; 
}


.sbfooterlinksdiv p:hover i {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

@media screen and (max-width: 768px) {
  .sbfooterlinks {
    flex-direction: column;
    align-items: center;
  }

  .sbfooterlinksdiv {
    width: 100%;
    margin: 1rem 0;
    text-align: center;
  }

  .sbfooterbelow {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .sbfooterbelowlinks p {
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .sbfooterlinksdiv h4 {
    font-size: 14px;
  }

  .sbfooterlinksdiv p {
    font-size: 12px;
  }

  .sbfooterbelow p {
    font-size: 12px;
  }

  .sbfooterbelowlinks p {
    font-size: 12px;
  }
}
