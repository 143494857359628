.aboutcontainer {
    padding: 0px;
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    line-height: 2;
    color: #000000;
  }
  
  .aboutheading,
  .teamheading,
  .qualityheading {
    font-size: 1.2rem;
    margin: 10px 0 0;
    color: lightgray;
}

.aboutheading,
.teamheading,
.qualityheading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    background-color: #f0f0f0; 
    text-align: center;
    margin-top: 20px;
}
  .aboutimage {
    position: relative;
    width: 100%;
    height: 400px; 
    
  }
  
  .aboutimage img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .aboutimage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); 
    z-index: 2; 
  }
  
  .abouttext {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    text-align: center; 
    z-index: 2; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
  }
  
  .lift{
    margin-top: -2rem;
  }
  .abouttext h6{
    color: grey;
  }
  .abouttext h2{
    font-size: 3rem;
    color: white;
  }
  
  .aboutinfo{
    background-color: #f8f8f8; 
    border-radius: 10px;
    font-size: 1.3rem;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    width: 100%;
    box-sizing: border-box;
    max-width: 100%; 
  }
  .teamheading{
    margin: 1rem;
  }
  .aboutheading,
  .teamheading,
  .qualityheading {
    text-align: center;
    font-size: 2.5rem;
    color: #000000; 
    letter-spacing: 1px;
    padding: 20px; 
    margin-bottom: 20px; 
    border-radius: 5px; 
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  }
  
  @media (max-width: 768px) {
    .aboutheading,
    .teamheading,
    .qualityheading {
      font-size: 2rem; 
      padding: 15px; 
    }
  }
  
  .teamsection {
    display: flex;
    justify-content: space-between; 
    gap: 0; 
    margin-top: 1rem;
    margin-bottom: 10px;
    flex-wrap: wrap; 
  }
  
  .teampolicycard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: calc(50% - 20px);
    box-sizing: border-box;
    max-width: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
  
  .teampolicycard:hover {
    transform: translateY(-5px); 
  }
  
  .teamimage2 img {
    width: 100%; 
    height: 20rem;  
    padding: 20px;
    object-fit: cover; 
    border-radius: 10px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .teamimage2 img:hover {
    transform: scale(1.05); 
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
  .teamimage1 img {
    width: 100%;
    height: 20rem;
    margin-top: 1rem;
    padding: 5px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
  
  .teamimage1 img:hover {
    transform: scale(1.05); 
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
  
  .teaminfo {
    text-align: center;
    padding: 5px;
  }
  
  .teaminfo h3 {
    font-size: 1.2rem;
    color: #333333; 
    margin-bottom: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase; 
  }
  
  .teaminfo p {
    font-size: 1.1rem;
    color: #555555; 
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .qualitypolicycard {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: #f8f8f8; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    margin: 40px auto; 
    max-width: 600px; 
    transition: transform 0.3s ease; 
  }
  
  .qualitypolicycard:hover {
    transform: translateY(-5px); 
  }
  
  .qualityimage img {
    max-width: 100%; 
    height: auto; 
    border-radius: 10px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .qualityimage img:hover {
    transform: scale(1.05); 
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); 
  }
  
  .qualityinfo {
    text-align: center; 
    margin-top: 20px; 
  }
  
  .qualityinfo p {
    font-size: 1.2rem;
    color: #000000; 
    line-height: 1.6;
  }
  .fgt {
    width: 90%;
    margin: 0 auto; 
    display: block;
    text-align: center; 
}

  img:hover {
    opacity: 0.9; 
    transition: opacity 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .teamsection {
      flex-direction: column;
      align-items: center;
    }
  
    .teampolicycard {
      width: 80%; 
      margin-bottom: 20px;
    }
  }