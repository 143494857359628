
.maincontainer{
    padding: 0%;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial,sans-serif;
    line-height: 1.8;
    color: black;
    }
    
    .imagecontainer{
        position: relative;
        height: 400px;
        width: 100%;
    }
    
    .imagecontainer img{
       height: 100%;
       width: 100%;
       object-fit: cover;
       position: absolute;
       top: 0;
       left: 0;
       z-index: 1;
    }
    
    .imagecontainer::before{
       content: '';
       position: absolute;
       height: 100%;
       width: 100%;
       top: 0;
       left: 0;
       background-color: rgba(0,0,0,0.7);
       z-index: 2;
    }
    .textoverlay{
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
        width: 40vw;
      
      }
    
    .textoverlay h2 {
        color: rgb(65, 117, 177);
       font-size: 5rem;
        margin-top: -6rem;
    }
     .textoverlay h6 {
       color: lightgray;
       font-size: 1rem;
       margin-top: 1rem;
      }
    
      .fo{
        margin-top: -8rem;
      }
    
      .textheading{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100px; /* Adjust as per need */
          font-size: 2rem;
          font-weight: bold;
          color: #333;
          background-color: #f0f0f0; /* Optional: Background color */
          text-align: center;
          margin-top: 20px;
      }
    
      .gallerygrid {
        display: grid;
        grid-template-columns: repeat(5, 1fr); 
        gap: 10px;
        padding: 20px;
      }
      
      .galleryitem {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border-radius: 5px;
      }
      
      .galleryitem img {
        width: 80%;
        height: 80%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
      
      .galleryitem:hover img {
        transform: scale(1.1); 
      }
      
      .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 4;
      }
      
      .modalcontent {
        position: relative;
        max-width: 90%;
        max-height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .modalcontent img {
        width: 100%;
        max-width: 600px;
        height: 100%;
      }
      
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 40px;
        color: white;
        cursor: pointer;
        z-index: 5;
      }
      
      .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        padding: 16px;
        margin-top: -22px;
        color: white;
        font-weight: bold;
        font-size: 24px;
        border: none;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;
        transform: translateY(-50%);
      }
      
      .prev {
        left: -50px; 
      }
      
      .next {
        right: -50px; 
      }
      
      .prev:hover, .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
      @media (max-width: 768px) {
        .textoverlay h2 {
            font-size: 2.2rem;
        }
    
        .textoverlay h6 {
            font-size: 1rem;
        }
    
        .gallerygrid {
            grid-template-columns: repeat(3, 1fr);
        }
    
        .imagecontainer {
            height: 250px;
        }
    
        .galleryitem img {
            width: 100%;
            height: 100%;
        }
    
        .galleryitem:hover img {
            transform: none;
        }
    
        .prev, .next {
            font-size: 18px;
            padding: 10px;
        }
    }
    
    @media (max-width: 480px) {
        .textoverlay h2 {
            font-size: 1.8rem;
        }
    
        .textoverlay h6 {
            font-size: 0.9rem;
        }
    
        .textoverlay {
            width: 80vw;
        }
    
        .textheading {
            font-size: 2rem;
        }
    
        .gallerygrid {
            grid-template-columns: repeat(1, 1fr);
        }
    
        .imagecontainer {
            height: 200px;
        }
    
        .galleryitem img {
            width: 100%;
            height: 100%;
        }
    
        .prev, .next {
            font-size: 16px;
            padding: 8px;
        }
    }
    