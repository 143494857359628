.Liftcontainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center; 
}


.Liftwrapper {
    position: relative;
    width: 100%;
    height: 42rem;
}

.Liftwrapper img {
    width: 100%;
    height: 42rem;
}



.controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    transform: translateY(-50%); 
}

.controlbuttonleft , .controlbuttonright {
    background: transparent; 
    border: none;
    font-size: 3rem; 
    color: white; 
    cursor: pointer; 
    transition: color 0.3s; 
}

.controlbuttonleft:hover , .controlbuttonright:hover {
    color: rgb(0, 0, 0);
}

.imagenumber {
    font-size: 1.5rem;
    color: white;
    position: absolute;
    right: 10%; 
    top: 5%; 
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.5rem; 
    background-color: black; 
    padding: 10px; 
    border-radius: 5px;
}

.Qualitytext {
    font-size: 4rem;
    font-weight: bold;
    margin-top: 1rem;
    color: rgb(100, 100, 100);
    transition: transform 0.3s ease, color 0.3s ease;
    -webkit-transition: transform 0.3s ease, color 0.3s ease;
    -moz-transition: transform 0.3s ease, color 0.3s ease;
    -ms-transition: transform 0.3s ease, color 0.3s ease;
    -o-transition: transform 0.3s ease, color 0.3s ease;
}

.Liftcontainer .Qualitytext:hover {
    transform: scale(1.2);
    color: black;
}


.text1 {
    font-size: 1rem; 
    font-weight: bold;
    color: rgb(100, 100, 100); 
    margin-top: 5rem; 
    text-transform: uppercase; 
    letter-spacing: 2px; 
    transition: transform 0.3s ease, color 0.3s ease;
}

.text2 {
    font-size: 2rem;
    font-weight: bold;
    color: rgb(65, 117, 177);
    margin-top: 0.5rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    transition: transform 0.3s ease, color 0.3s ease;
    -webkit-transition: transform 0.3s ease, color 0.3s ease;
    -moz-transition: transform 0.3s ease, color 0.3s ease;
    -ms-transition: transform 0.3s ease, color 0.3s ease;
    -o-transition: transform 0.3s ease, color 0.3s ease;
}

.text1:hover, .text2:hover {
    transform: scale(1.1);
    color: black; 
}

.sec {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
}

.sec1, .sec2 {
    flex: 1; 
    padding: 3rem; 
}

.sec img {
    width: 100%;
    height: auto; 
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); 
    border-radius: 10px;
}

.sec21, .sec-22, .sec-23, .sec-24 {
    margin-bottom: 1.5rem; 
}

.sec21 {
    font-size: 2rem;
    font-weight: bold;
    color: black; 
}

.sec22 {
    font-size: 1.5rem; 
    color: gray; 
}

.sec23 {
    font-size: 1rem;
    color: black;
    line-height: 1.5;
}

.sec24 button {
    background-color: black; 
    color: white; 
    padding: 0.8rem 1.5rem; 
    border: none; 
    border-radius: 5px;
    font-size: 1rem; 
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sec24 button:hover {
    background-color: rgb(65, 117, 177);
}

.main3 {
    position: relative; 
    margin-top: -1rem;
    padding: 2rem;
}

.main3 img {
    width: 100%;
    height: 40rem; 
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.main3textbox {
    position: absolute;
    top: 80%; 
    right: 5%; 
    transform: translateY(-50%); 
    width: 30%; 
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.7); 
    color: black; 
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.main3 .tre11 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.main3 .tre12 {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.tre13 button {
    background-color: black; 
    color: white;
    padding: 0.8rem 1.5rem;
    border: none; 
    border-radius: 5px; 
    font-size: 1rem; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
}

.tre13 button:hover {
    background-color: rgb(65, 117, 177); 
}

.slast {
    position: relative; 
    padding: 10rem; 
    text-align: center;
}

.slast .last1 {
    font-size: 8rem;
    font-weight: bold; 
    text-transform: uppercase; 
    letter-spacing: 0.2rem;
    color: lightgrey;
    position: absolute; 
    top: 60%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    z-index: 1; 
    opacity: 0.5; 
    width: 100%;
    height: 100%;
    overflow: hidden; 
}

.slast .ls1, .slast .ls2 {
    position: relative;
    z-index: 2; 
    color: black; 
}

.slast .ls1 {
    margin-top: 1rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.slast .ls2 {
    font-size: 3rem; 
    font-weight: bold; 
}

.iconscontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4rem; 
  }
  
  .icongroup {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    margin: 0 6rem; 
    width: 200px; 
    margin-top: 3rem; 
  }
  
  .iconscontainer svg {
    font-size: 5rem;
    color: rgb(65, 117, 177);
    margin: 0 5rem;
    background-color: lightgrey;
    padding: 2rem;
    border-radius: 50%;
   box-shadow: 0 4px 10px rgba(5, 4, 4, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease;
     
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .iconscontainer svg:hover {
    background-color: black; 
    color: white;
  }
  
  .icontext {
    font-size: 1.5rem; 
    color: rgb(65, 117, 177); 
    margin-top: 1rem; 
    font-weight: bold;
  }
  
  .subtext {
    font-size: 1rem; 
    color: gray; 
    margin-top: 0.5rem;
  }
  
  @media (max-width: 480px) {
    .Liftwrapper {
        height: 20rem;
    }

    .Liftwrapper img {
        height: 20rem;
    }

    .controlbutton {
        font-size: 2rem;
    }

    .imagenumber {
        font-size: 1rem;
    }

    .caption {
        font-size: 1rem;
        padding: 5px;
    }

    .Qualitytext {
        font-size: 2rem;
    }

    .text1 {
        font-size: 0.6rem;
    }

    .text2 {
        font-size: 1.2rem;
    }

    .sec {
        flex-direction: column;
        padding: 1rem;
    }

    .sec img {
        margin-bottom: 1rem;
        width: 100%;
    }

    .sec1, .sec2 {
        padding: 0.5rem;
    }

    .iconscontainer {
        flex-direction: column;
    }

    .icongroup {
        width: 100%;
        margin: 1rem 0;
    }

    .main3textbox {
        width: 80%;
        top: 70%;
    }

    .main3 img {
        height: auto;
    }

    .slast {
        padding: 5rem 2rem;
    }

    .slast .last1 {
        font-size: 4rem;
    }

    .slast .ls2 {
        font-size: 2rem;
    }
}