.chatcontainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chatbutton {
    background-color: #007bff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
}

.chatbutton:hover {
    background-color: #0056b3;
}

.chaticon {
    font-size: 24px;
    color: white;
}

.chatbox {
    background-color: white;
    border-radius: 8px;
    width: 90%; 
    max-width: 400px; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: fixed;
    bottom: 80px;
    right: 5%;
    transition: all 0.3s ease;
}

.chatheader {
    background-color: #007bff;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.chatbody {
    padding: 10px;
    max-height: 60vh;
    overflow-y: auto;
}

.usermessage {
    background-color: #d1e7dd;
    color: black;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-end;
}

.botmessage {
    background-color: #f8d7da;
    color: black;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-start;
}

input[type="text"],
input[type="email"],
select {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
    width: 100%; /* Full width for mobile */
}

button:hover {
    background-color: #0056b3;
}

.closebtn {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #ff4d4d;
    position: absolute;
    right: 10px;
    top: 10px;
}

.closebtn:hover {
    color: #ff1a1a;
}


@media only screen and (max-width: 600px) {
    .chatbox {
        width: 100%;
        bottom: 20px;
        right: 0;
        left: 0; 
    }

    .chatbutton {
        width: 50px;
        height: 50px;
    }

    .chaticon {
        font-size: 20px;
    }

    .chatbody {
        max-height: 50vh; /* Smaller height for mobile screens */
    }

    button {
        font-size: 14px; /* Adjust button font size for mobile */
    }
}
