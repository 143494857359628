.main-container {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: black;
  height: 100vh;
}

.image-container {
  position: relative;
  height: 400px; 
  width: 100%;  
  overflow: hidden;
}

.image-container video {
  height: 100%; 
  width: 100%; 
  object-fit: cover; 
  position: absolute; 
  top: 0; 
  left: 0; 
  z-index: 1; 
}

.team-image video {
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  border-radius: 10px; 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 2;
}

.text-overlay{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
  width: 40vw;
}

.text-overlay h2 {
  color: #ffffff;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.text-overlay h6 {
  color: #ffffff;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.fo {
  margin-top: -2rem;
}

.card-container {
  margin-left: 18rem;
  display: flex;
  justify-content: center;
  gap: 30px; 
  max-width: 1000px;
  margin: 1 auto;
  padding: 0 0; 
}
.card-container1 {
  /* margin-left: 18rem; */
  display: flex;
  justify-content: center;
  gap: 40px; 
  max-width: 100%;
  margin: 1 auto;
  padding: 7rem;
}

.card {
  margin: 0 auto;
  padding: 2rem;
  width: 400px;
  background: rgb(254, 254, 255);
  text-align: center;
  border-radius: 10px;
  position: relative;
}

.card::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: conic-gradient(from var(--angle), transparent 50%, #bf8500);
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 3px;
  border-radius: 10px;
  animation: 3s spin linear infinite;
}

.card::before {
  filter: blur(1.5rem);
  opacity: 0.5;
}

@keyframes spin {
  from {
      --angle: 0deg;
  }
  to {
      --angle: 360deg;
  }
}

.map-frame {
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;   
}

.map {
  width: 100%;
  height: 100%;
  display: block;
}

.card-info {
  background-color: #bf8500; 
  border-radius: 10px;       
  padding: 5px;            
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  margin: 10px 0;    
  height: 450px;   
}

.card-info ul {
  list-style-type: none;    
  margin: 5px;
  padding: 5px;     
}

.card-info li {
  margin-bottom: 15px;       
  font-size: 1rem;        
  color: #333;   
  font-family: Arial, Helvetica, sans-serif;    
  margin-left: 5px;
  padding: 5px;    
}

.card-info li span {
  display: block;           
  margin: 5px;
  padding: 5px;        
  color: white;  
  font-size: 1.1rem;     
  font-family: Arial, Helvetica, sans-serif;     
}

.card-info li ul {
  list-style-type: none;     
  margin: 5px;
  padding: 5px;             
}

.card-info li ul li {
  margin-bottom: 5px;        
  font-size: 1rem;          
  font-family: Arial, Helvetica, sans-serif;   
  color: white;
  margin: 5px;
  padding: 5px;
}

.heading {
  text-align: center;
  font-size: 2rem;
  color: #4d2900;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  font-family: Arial, Helvetica, sans-serif;
}

.head {
  text-align: center;
  font-size: 2rem;
  color: #4d2900;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
  padding: 10px;
}

.card-info i {
  margin-right: 10px; 
}
/* Responsive design for tablets and smaller screens (768px and below) */
@media (max-width: 768px) {
  .main-container {
      padding: 1rem;
      height: auto;
  }

  .image-container {
      height: 250px;
  }

  .text-overlay h2 {
      font-size: 2rem;
  }

  .text-overlay h6 {
      font-size: 1rem;
  }

  .card-container, .card-container1 {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-left: 0;
      padding: 1rem;
  }

  .card {
      width: 90%;
      padding: 1.5rem;
      margin: 0 auto;
  }

  .card-info {
      height: auto;
      padding: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #bf8500;
  }

  .map-frame, .map {
      width: 100%;
      height: 300px;
  }

  .heading, .head {
      font-size: 1.5rem;
      padding: 0.5rem;
  }

  .card-info li {
      font-size: 0.9rem;
      margin-bottom: 10px;
  }

  .card-info li span {
      font-size: 1rem;
  }
}

/* Responsive design for mobile screens (480px and below) */
@media (max-width: 480px) {
  .image-container {
      height: 200px;
  }

  .text-overlay h2 {
      font-size: 1.5rem;
  }

  .text-overlay h6 {
      font-size: 0.9rem;
  }

  .card-container, .card-container1 {
      padding: 0.5rem;
  }

  .card {
      padding: 1rem;
  }

  .card-info {
      padding: 0.8rem;
      margin: 0.5rem auto;
  }

  .heading, .head {
      font-size: 1.2rem;
  }

  .card-info li {
      font-size: 0.85rem;
      padding: 0.5rem;
  }

  .card-info li span {
      font-size: 0.95rem;
  }
}
