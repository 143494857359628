/* General Container Styles */
.help-container {
    position: relative;
    overflow: hidden;
    width: auto;
    max-width: 80%;
    margin: 5rem auto;
    border-radius: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 3rem;
    border: 4px solid goldenrod;
    background-color: #f0f9ff;
}

.help-header {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #0284c7;
    margin-bottom: 1rem;
}

.help-form {
    color: #374151;
}

/* Label and Input Fields */
.label {
    font-size: 1rem;
    font-weight: bold;
}

.input-field,
.textarea-field {
    width: 100%;
    padding: 0.8rem;
    margin: 0.5rem 0;
    outline: none;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    transition: border-color 0.3s ease;
}

.input-field:focus,
.textarea-field:focus {
    border-color: #38bdf8;
    box-shadow: 0 0 0 2px #38bdf8;
}

.textarea-field {
    resize: vertical;
    height: 4rem;
}

/* Submit Button */
.submit-btn {
    width: 100%;
    padding: 0.8rem;
    background-color: #0284c7;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0369a1;
}

/* Image Overlay */
.image-container {
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;
}

.image-container video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.image-container::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.text-overlay h2 {
    font-size: 2rem;
}

.text-overlay h6 {
    font-size: 1.2rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .help-container {
        left: 0;
        width: 90%;
        padding: 2rem;
        margin: 3rem auto;
    }

    .text-overlay h2 {
        font-size: 1.5rem;
    }

    .text-overlay h6 {
        font-size: 1rem;
    }

    .input-field,
    .textarea-field,
    .submit-btn {
        padding: 0.6rem;
    }
}

@media (max-width: 480px) {
    .image-container {
        height: 250px;
    }

    .text-overlay h2 {
        font-size: 1.2rem;
    }

    .text-overlay h6 {
        font-size: 0.9rem;
    }

    .help-container {
        padding: 1.5rem;
    }

    .help-header {
        font-size: 1.4rem;
    }
}
