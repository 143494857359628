body {

  background-image: repeating-linear-gradient(
    -45deg,
    orange 0,
    transparent 30px,
    orangered 30px,
    transparent 60px,
    orange 120px,
    transparent 240px
  );
  background-color: orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

