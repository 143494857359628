.Liftcontainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Ensures no overflow beyond the container */
}

.cardmain{
  position: relative;
  display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.pmain{
  position: relative;
  display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.pmain1{
    width: 50%;
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    font-size: 6rem;
    color: #6293c8;
}
.pmain2{
    width: 50%;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
    font-size: 1.5rem;
    margin-top: -4rem;
    color: #e4645a;
}

.Liftwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.Liftvideo {
  width: 50rem;
  height: auto;
  max-width: 200rem;
  min-width: 20rem;
  transition: width 0.5s ease;
  object-fit: contain;
  border: 1px solid black;
  padding: 5px;
  filter: blur(0px) brightness(0.5);
  -webkit-filter: blur(0px) brightness(0.7);
}


.caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent background for better readability */
    padding: 10px;
    border-radius: 5px;
}

.card {
    display: block;
    position: relative;
    max-width: 400px;
    max-height: 420px;
    background-color: #f2f8f9;
    border-radius: 10px;
    padding: 2em 1.2em;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .cardtitle {
    color: #262626;
    font-size: 1.5em;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  
  .smalldesc {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    color: #452c2c;
  }
  
  .gocorner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 2em;
    height: 2em;
    overflow: hidden;
    top: 0;
    right: 0;
    background: linear-gradient(135deg, #6293c8, #384c6c);
    border-radius: 0 4px 0 32px;
  }
  
  .goarrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: linear-gradient(135deg, #364a60, #384c6c);
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.35s ease-out;
  }
  
  .card:hover:before {
    transform: scale(28);
  }
  
  .card:hover .smalldesc {
    transition: all 0.5s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .card:hover .cardtitle {
    transition: all 0.5s ease-out;
    color: #ffffff;
  }
  .custom_card {
    margin-left: 8rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: relative;
    width: 1400px;
    height: 500px;
    background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .custom_card__icon {
    width: 48px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .custom_card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .custom_card__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .custom_card:hover .custom_card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  
  .custom_card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
  }
  
  
  .custom_card:hover .custom_card__icon {
    scale: 0;
    transform: rotate(-45deg);
  }
.countmain{
  font-size: 3rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  color: rgb(4, 35, 61);
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .pmain1 {
      width: 100%;
      font-size: 3rem;
      margin-top: 2rem;
  }

  .pmain2 {
      width: 100%;
      font-size: 1rem;
      margin-top: 1rem;
  }

  .Liftcontainer, .cardmain, .pmain, .Liftwrapper {
      flex-direction: column;
  }

  .Liftvideo {
      width: 100%;
      padding: 0;
  }

  .card {
      max-width: 300px;
      margin: 8px;
      padding: 1em;
  }

  .cardtitle {
      font-size: 1.2em;
  }

  .smalldesc {
      font-size: 0.9em;
  }

  .custom_card {
      width: 100%;
      height: auto;
      margin: 2rem 0;
      transform: none;
  }

  .custom_card__title {
      font-size: 1.5rem;
  }

  .countmain {
      font-size: .5rem;
  }
}