.mainModern {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  background-image: url('./Images/moder.jpg'); /* Background image */
  background-size: cover; /* Makes sure the image covers the entire div */
  background-position: center; /* Centers the background image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.textModern {
  position: relative; /* Keeps the text centered */
  padding: 20px; /* Adds padding around the text */
  text-align: center;
  border-radius: 10px; /* Optional: Rounds the corners of the text container */
}

.h3Modern {
  font-weight: bold;
  font-size: 5rem;
  margin: 0;
  color: rgb(65, 117, 177); 
}

.soModern {
  font-size: 1.2rem;
  margin: 10px 0 0;
  color: lightgray;
}

.secModern {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as per need */
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0; /* Optional: Background color */
  text-align: center;
  margin-top: 20px;
}

.sec1Modern, .sec2Modern {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.9rem;
  padding: 20px;
  line-height: 1.6;
  margin: 20px auto; /* Center align with auto margins */
  border-radius: 10px; /* Optional: Rounded corners */
  color: black;
  max-width: 1400px; /* Limit width for readability */
}

.sec3Modern {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as per need */
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0; /* Optional: Background color */
  text-align: center;
  margin-top: 20px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  color: #f0f0f0;
}

.feature, .feature1, .feature2, .feature3, .feature4, .feature5, .feature6 {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(22, 218, 25, 0.813);
  /* Ensure consistent styling */
}

.feature {
  width: 250px;
  background-color: #80AD40;
}

.feature1 {
  width: 300px;
  background-color: #0F6938;
}

.feature2 {
  width: 300px;
  background-color: #E2963B;
}

.feature3 {
  width: 200px;
  background-color: #D85B26;
}

.feature4 {
  width: 400px;
  background-color: #1472A4;
}

.feature5 {
  width: 300px;
  background-color: #2591C9;
}

.feature6 {
  width: 400px;
  background-color: #BC1F5C;
}

.featureTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Image Grid */
.imageGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 20px;
  justify-content: center;
  margin: 40px auto; /* Space around the grid */
  max-width: 700px;
}

.modernizationImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow to the images */
  object-fit: cover;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
.mainModern {
  height: 300px; /* Reduce the height for mobile */
}

.h3Modern {
  font-size: 2.5rem; /* Smaller font size for mobile */
}

.soModern {
  font-size: 1rem; /* Adjust subtitle size */
}

.secModern, .sec3Modern {
  font-size: 1.5rem; /* Smaller font size for section titles */
  height: 80px; /* Reduce the height */
}

.sec1Modern, .sec2Modern {
  font-size: 0.9rem; /* Adjust paragraph font size */
  padding: 15px; /* Reduce padding */
}

.features {
  padding: 10px; /* Reduce padding */
  gap: 20px; /* Reduce gap between feature blocks */
}

.feature, .feature1, .feature2, .feature3, .feature4, .feature5, .feature6 {
  width: 100%; /* Make features take full width */
  padding: 10px; /* Reduce padding */
}

.imageGrid {
  grid-template-columns: 1fr; /* Single column for mobile */
  gap: 20px; /* Reduce gap between images */
  max-width: 100%; /* Full width on mobile */
}

.modernizationImage {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
}
