.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.639), #458ac6f7);
  position: relative;
  z-index: 100;
}

.navbar-logo img {
  height: 6rem;
  width: 16rem;
}

.navbar-menu ul {
  list-style: none;
  display: flex;
  gap: 40px;
}

.navbar-menu ul li {
  position: relative;
}

.buttoni {
  font-size: 20px;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  background-color: transparent;
  color: black;
}

.icon {
  font-weight: normal;
}

/* Dropdown Menu Styling */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  min-width: 250px;
  z-index: 9999;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.dropdown-content a {
  color: black;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: rgb(123, 123, 228);
}

/* Show dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger .line {
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 2px;
  transition: transform 0.3s ease;
}

.hamburger:focus {
  outline: none;
}

/* Mobile Menu Styling */
.navbar-menu.open ul {
  display: block;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.navbar-menu ul {
  display: flex;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: auto;
  }

  .navbar-logo {
    flex: 1;
    text-align: left;
    width: auto;
    margin-right: auto;
  }

  .navbar-menu ul {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }

  .navbar-menu.open ul {
    display: flex;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    transform: none;
  }

  /* Show dropdowns in mobile only when the menu is open */
  .navbar-menu.open .dropdown .dropdown-content {
    display: block;
  }

  .hamburger {
    display: flex;
    margin-left: auto;
  }
}
