.maincontainer{
padding: 0%;
max-width: 1200px;
margin: 0 auto;
gap: 50px;
font-family: Arial,sans-serif;
line-height: 1.8;
color: black;

}

.imagecontainer{
    position: relative;
    height: 400px;
    width: 100%;
    animation-name: slide-in;  
    animation-duration: 2s;     
    animation-fill-mode: forwards;  
}

.imagecontainer img{
   height: 100%;
   width: 100%;
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;
}
.teaimage img {
    width: 100%; 
    height: auto; 
    object-fit: cover; 
    border-radius: 10px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
.imagecontainer::before{
   content: '';
   position: absolute;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   background-color: rgba(0,0,0,0.7);
   z-index: 2;
}
.textoverlay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
    width: 40vw;
  }

.textoverlay h2 {
    color: #ffffff;
   font-size: 3rem;
   font-family: Arial, Helvetica, sans-serif;
    
}
 .textoverlay h6 {
   color: #ffffff;
   font-size: 1.3rem;
   font-family: Arial, Helvetica, sans-serif;
  }

  .fo{
    margin-top: -2rem;
  }


  
@keyframes slide-in {
    0% {
      opacity: 0;          
      transform: translateX(-100px);  
    }
    100% {
      opacity: 1;          
      transform: translateX(0);  
    }
  }
  
  
  .textheading {
    font-size: 2rem;
    text-align: center;
    margin: 20px 0;
    animation-name: slide-in;  
    animation-duration: 2s;     
    animation-fill-mode: forwards;  
    font-family: Arial, Helvetica, sans-serif;
  }

.cardcontainer {
    display: flex;
    justify-content: space-between; 
    gap: 20px; 
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0; 
  }
  
 
  .qualitypolicycard {
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    background-color: #f8f8f8; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease; 
    animation-name: slide-in;  
    animation-duration: 2s;     
    animation-fill-mode: forwards; 
  }
  .cardcontain{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: -100rem;
    margin-top: 3rem;
    margin-bottom: 3rem;

  }
  .card {
    top: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    border-radius: 24px;
    line-height: 1.6;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content {
    align-items: center;
    padding: 3rem;
    align-content: center;
    width: 58rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 22px;
    color: #ffffff;
    background: #93a3e6;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}
  
  .content::before {
    position: absolute;
    content: "";
    top: -4%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%);
    background: #ced8ff;
    z-index: -1;
    transform-origin: bottom;
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content::after {
    position: absolute;
    content: "";
    top: -8%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%);
    background: #e7ecff;
    z-index: -2;
    transform-origin: bottom;
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content svg {
    width: 48px;
    height: 48px;
  }
  
  .content .para {
    z-index: 1;
    opacity: 1;
    font-size: 18px;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content .link {
    z-index: 1;
    color: #fea000;
    text-decoration: none;
    font-family: inherit;
    font-size: 16px;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content .link:hover {
    text-decoration: underline;
  }
  
  .card:hover {
    transform: translate(0px, -16px);
  }
  
  .card:hover .content::before {
    rotate: -8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .card:hover .content::after {
    rotate: 8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
  

  .teampolicycard {
    flex: 1; 
    display: flex;
    margin-top: 2.5rem;
    height: 630px; 
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8; 
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease; 
    animation-name: slide-in;  
    animation-duration: 2s;     
    animation-fill-mode: forwards;  
  }
  
  .teamimage img {
    width: 100%; 
    height: 400px; 
    object-fit: cover; 
    margin-top: 1rem;
    border-radius: 10px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .teampolicycard:hover, 
  .qualitypolicycard:hover {
    transform: translateY(-5px); 
  }
  
  .qualityinfo p {
    font-size: 1.2rem;
    color: #000000; 
    line-height: 1.6;
    font-family: Arial, Helvetica, sans-serif;
  }
  

  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .textheading {
    font-size: 2rem;
    text-align: center;
    margin: 20px 0;
    animation-name: slide-in;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  @media (max-width: 768px) {
    .textoverlay h2 {
        font-size: 2.5rem;
    }

    .textoverlay h6 {
        font-size: 1rem;
    }

    .cardcontainer {
        flex-direction: column; 
        align-items: center;
    }

    .qualitypolicycard, .team-policy-card {
        width: 100%; 
        margin-bottom: 20px;
    }

    .teamimage img {
        width: 100%; 
        height: auto;
    }


}

@media (max-width: 480px) {
    .textoverlay h2 {
        font-size: 2rem;
    }

    .textoverlay h6 {
        font-size: 0.9rem;
    }

    .cardcontainer {
        padding: 10px;
    }

    .qualityinfo p {
        font-size: 1rem; 
    }

}
  