.overlay {
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 100vh;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #619bcd04);}

.form {
    height: auto;
    width: 36em;
    padding: 6%;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.639), #619bcda8);
    box-shadow: 12px 12px 18px #bababa,-12px -12px 18px #ffffff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
}

.wrapper {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .wrapper .icon {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
  }
  
  .wrapper .icon .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
  }
  
  .wrapper .icon:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  .wrapper .icon svg {
    fill: #333;
    transition: fill 0.3s;
  }
  
  .wrapper .icon:hover svg {
    fill: #007bff;
  }
  


  .socialicons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0;
    list-style: none;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
  }
  
  .icon:hover {
    transform: scale(1.1);
  }
  
  .icon svg {
    fill: #333;
    transition: fill 0.3s; 
  }
  
  .icon:hover svg {
    fill: #007bff; 
  }
  


.successmessage {
    text-align: center;
    font-size: 1.5em;
    margin: 20px 0;
}

#btndone {
    font-size: large;
    letter-spacing: 2px;
    color: white;
    font-weight: 700;
    background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border-radius: 8px;
    border: none;
    padding: 10px 20px;
    transition: all 300ms ease-in;
}

#btndone:hover {
    background: linear-gradient(144deg, #9706ff, #2f0fff 50%, #18f0ff);
}


.input {
    height: 40px; 
    margin-bottom: 10px; 
    padding: 1px 8px;
    border: none;
    font-size: 1em;
    letter-spacing: 2px;
    border-radius: 8px;
    background: #f3f3f3;
    box-shadow: inset -2px -2px 4px #ffffff,
    inset 2px 2px 4px rgba(0, 0, 0, 0.356);
    background: linear-gradient(to right, white, #80808010, white);
    background-size: 200% 200%;
    animation: input 4s infinite;
}

.input:focus-visible {
    outline: none;
    background: rgb(241, 241, 241);
}

select.input {
    appearance: none;
    background: #f3f3f3;
    padding: 1px 8px;
    cursor: pointer; 
}


#btn {
    font-size: x-large;
    letter-spacing: 3px;
    color: white;
    font-weight: 700;
    height: 18%;
    background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border-radius: 8px;
    border: none;
    box-shadow: inset 1px 3px 3px #ffffffbd,
                 inset -4px -4px 3px #00000046;
    background-size: 150% 150%;
    animation: input 5s infinite;
    transition: all 900ms ease-in;
}

#btn:hover {
    position: relative;
    bottom: 3px;
    background: linear-gradient(144deg, #9706ff, #2f0fff 50%, #18f0ff);
}

@keyframes input {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
