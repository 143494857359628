.startscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full-screen height */
    width: 100vw; /* Full-screen width */
    background-color: #000; /* Black background */
    transition: opacity 1s ease-out; /* Fade-out effect */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-out;
    -ms-transition: opacity 1s ease-out;
    -o-transition: opacity 1s ease-out;
}


/* Start.css */
.blackscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
  }
  

.fadeout {
    opacity: 0; /* Fade out */
}

.startvideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
}

.textoverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2;
}

/* Default for larger screens */
.welcometext,
.companyname,
.tagline {
    font-size: 4rem;
}

.welcometext {
    color: #FFCC00;
}

.companyname {
    color: #00CCFF;
}

.tagline {
    color: #FF6600;
}

/* Media query for smaller screens (mobile devices) */
@media (max-width: 768px) {
    .welcometext,
    .companyname,
    .tagline {
        font-size: 2rem; /* Reduce font size for mobile */
    }

    .textoverlay {
        padding: 0 10px; /* Add some padding for mobile */
    }
}

@media (max-width: 480px) {
    .welcometext,
    .companyname,
    .tagline {
        font-size: 1.5rem; /* Further reduce font size for very small screens */
    }

    .textoverlay {
        padding: 0 5px; /* Narrow padding for small screens */
    }
}

.blackbackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Black background */
    z-index: 9998; /* Ensure it's behind the start screen */
    transition: opacity 1s ease; /* Add transition for fade effect */
    opacity: 1; /* Ensure it's fully visible */
}
